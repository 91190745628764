import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Nedan så har vi samlat ihop dagens skörd av alla free spins erbjudande för den 18 februari 2014.`}</p>
    <p><strong parentName="p">{`Bertil – 20 free spins i mobilen`}</strong></p>
    <p>{`Bra erbjudande, som är 20 free spins i mobilen varje dag hela veckan tills den 23 februari hos Bertil. Sätta in 100 kronor och få 20 free spins. Du kan ta del av erbjudandet varje dag fram till den 23 februari 2014`}</p>
    <p>{`Hämta 20 free spins – Spela nu!`}</p>
    <p><strong parentName="p">{`ComeOn – Spela och få 80 free spins`}</strong>{`
Hos ComeOn så kan man även idag hämta hem 80 free spins på Big Bang igenom att spela något av följande spel Hot City, Immortal Romance, Jack och Jill eller Burning Desire. Man får 10 free spins om man vinner 50kr, 20 free spins om man vinner 100kr och 50 free spins om man vinner 250kr
Spela free spins hos ComeOn här!`}</p>
    <p>{`Spela och få 80 free spins – Hämta här`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      